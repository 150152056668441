import React from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";
import "react-image-crop/dist/ReactCrop.css";

const ctable = [
  {
    name: "Budiakala Fogo",
    health: "Heart Failure",
    number: "Nov 04, 2023",
    case: "Completed",
    affected: "Validate",
    pro: "Reject",
    total: "Delete",
  },
  {
    name: "Budiakala Fogo",
    health: "Heart Failure",
    number: "Nov 04, 2023",
    case: "Completed",
    affected: "Validate",
    pro: "Reject",
    total: "Delete",
  },
  {
    name: "Budiakala Fogo",
    health: "Heart Failure",
    number: "Nov 04, 2023",
    case: "Completed",
    affected: "Validate",
    pro: "Reject",
    total: "Delete",
  },
  {
    name: "Budiakala Fogo",
    health: "Heart Failure",
    number: "Nov 04, 2023",
    case: "Completed",
    affected: "Validate",
    pro: "Reject",
    total: "Delete",
  },
  {
    name: "Budiakala Fogo",
    health: "Heart Failure",
    number: "Nov 04, 2023",
    case: "Completed",
    affected: "Validate",
    pro: "Reject",
    total: "Delete",
  },
];

const payment = [
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
];

const service = [
    {
      services: "Health",
      amount: "$2000.00",
      date: "Nov 04, 2023",
      invoice: "invoice#1",
      download: "télécharger",
    },
    {
      services: "Health",
      amount: "$2000.00",
      date: "Nov 04, 2023",
      invoice: "invoice#1",
      download: "télécharger",
    },
    {
      services: "Health",
      amount: "$2000.00",
      date: "Nov 04, 2023",
      invoice: "invoice#1",
      download: "télécharger",
    },
    {
      services: "Health",
      amount: "$2000.00",
      date: "Nov 04, 2023",
      invoice: "invoice#1",
      download: "télécharger",
    },
    {
      services: "Health",
      amount: "$2000.00",
      date: "Nov 04, 2023",
      invoice: "invoice#1",
      download: "télécharger",
    },
    {
      services: "Health",
      amount: "$2000.00",
      date: "Nov 04, 2023",
      invoice: "invoice#1",
      download: "télécharger",
    },
    {
      services: "Health",
      amount: "$2000.00",
      date: "Nov 04, 2023",
      invoice: "invoice#1",
      download: "télécharger",
    },
    {
      services: "Health",
      amount: "$2000.00",
      date: "Nov 04, 2023",
      invoice: "invoice#1",
      download: "télécharger",
    },
    {
      services: "Health",
      amount: "$2000.00",
      date: "Nov 04, 2023",
      invoice: "invoice#1",
      download: "télécharger",
    },
  ];

export default function History() {
  return (
    <>
      <div className="grid lg:grid-cols-12 ">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 h-[100vh] overflow-y-scroll">
          <Navbar />
          <div className="w-[95%] mx-auto pt-10 mt-20 ">
            <p className="font-inter font-[700] text-[25px] text-black">
              History
            </p>

            <div className="flex justify-between gap-5 mt-10">
              <p className="font-inter font-[700] text-[18px] text-black">
                Service Request
              </p>

              <p className="self-center cursor-pointer font-inter font-[500] text-[15px] md:text-[18px] text-[#5E83F5]">
                Voir tout
              </p>
            </div>

            <div className="bg-[#BFD5FF] md:grid hidden grid-cols-7 px-5 py-3 rounded-t-lg mt-5">
              <div className="mx-auto">
                <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                  Available Services
                </p>
              </div>
              <div className="mx-auto">
                <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                  Eligible Services
                </p>
              </div>
              <div className="mx-auto">
                <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                  Service Requests
                </p>
              </div>
              <div className="mx-auto">
                <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                  Status
                </p>
              </div>
              <div className="mx-auto">
                <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                  Validate
                </p>
              </div>
              <div className="mx-auto">
                <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                  Reject
                </p>
              </div>
              <div className="mx-auto">
                <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                  Delete
                </p>
              </div>
            </div>

            {ctable.map((item) => (
              <div className="hidden md:grid grid-cols-7 px-5 py-3 border-[1px] border-gray-300">
                <div className="self-center mx-auto">
                  <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
                    {item.name}
                  </p>
                </div>

                <div className="self-center mx-auto">
                  <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
                    {item.health}
                  </p>
                </div>

                <div className="self-center mx-auto">
                  <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
                    {item.number}
                  </p>
                </div>

                <div className="self-center mx-auto">
                  <button className="font-inter font-[500] text-[10px] md:text-[13px] text-[#5E83F5] border-[1px] border-[#5E83F566] py-1 px-3 bg-[#e5ebfa]  rounded-full">
                    {item.case}
                  </button>
                </div>

                <div className="self-center mx-auto">
                  <button className="font-inter font-[500] text-[10px] md:text-[13px] text-white border-[1px] border-[#5E83F5] py-1 px-3 bg-[#5E83F5]  rounded-full">
                    {item.affected}
                  </button>
                </div>

                <div className="self-center mx-auto">
                  <button className="font-inter font-[500] text-[10px] md:text-[13px] text-[#FC3832] border-[1px] border-[#FC3832] py-1 px-3  rounded-full">
                    {item.pro}
                  </button>
                </div>

                <div className="self-center mx-auto">
                  <button className="font-inter font-[500] text-[10px] md:text-[13px] text-white border-[1px] border-[#FC3832] bg-[#FC3832] py-1 px-3  rounded-full">
                    {item.total}
                  </button>
                </div>
              </div>
            ))}
            {ctable.map((item) => (
              <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                <div className=" flex gap-2 justify-center">
                  <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                    {item.name}
                  </p>
                </div>
                <div className="mt-3">
                  <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                    {item.health}
                  </p>
                </div>
                <div className="mt-3">
                  <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                    {item.number}
                  </p>
                </div>
                <div className="mt-3 mx-auto">
                  <button className="font-inter font-[500] text-[10px] md:text-[13px] text-[#5E83F5] border-[1px] border-[#5E83F566] py-1 px-3 bg-[#e5ebfa]  rounded-full">
                    {item.case}
                  </button>
                </div>
                <div className="mt-3 mx-auto">
                  <button className="font-inter font-[500] text-[10px] md:text-[13px] text-white border-[1px] border-[#5E83F5] py-1 px-3 bg-[#5E83F5]  rounded-full">
                    {item.affected}
                  </button>
                </div>
                <div className="mt-3 mx-auto">
                  <button className="font-inter font-[500] text-[10px] md:text-[13px] text-[#FC3832] border-[1px] border-[#FC3832] py-1 px-3  rounded-full">
                    {item.pro}
                  </button>
                </div>
                <div className="mt-3 mx-auto">
                  <button className="font-inter font-[500] text-[10px] md:text-[13px] text-white border-[1px] border-[#FC3832] bg-[#FC3832] py-1 px-3  rounded-full">
                    {item.total}
                  </button>
                </div>
              </div>
            ))}

              <div className="mt-10 ">
                <div className="flex justify-between">
                  <p className="font-inter font-[700] text-[15px] md:text-[18px] text-black">
                    historique des paiements
                  </p>

                  <p className="self-center cursor-pointer font-inter font-[500] text-[15px] md:text-[18px] text-[#5E83F5]">
                    Voir tout
                  </p>
                </div>

                <div>
                  <div className=" bg-[#BFD5FF] hidden md:grid  grid-cols-12  py-3 rounded-t-lg mt-3">
                    <div className="col-span-3 text-center">
                      <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        Facture de paiement
                        {/* paiement est */}
                      </p>
                    </div>
                    <div className="col-span-2  text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        Montante
                        {/* quantité */}
                      </p>
                    </div>
                    <div className="col-span-3 text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        datte
                      </p>
                    </div>
                    <div className="col-span-2 text-center ">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        statut
                      </p>
                    </div>
                    <div className="col-span-2 text-center ">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        {/* Download */}
                        télécharger
                      </p>
                    </div>
                  </div>

                  {payment.map((item) => (
                    <div className="hidden md:grid grid-cols-12  py-4 border-[1px] border-gray-300 bg-white">
                      <div className="col-span-3  flex gap-2 justify-center">
                        <input type="checkbox" />
                        <p className=" self-center font-inter font-[500] text-[14px] text-[#2E3646]">
                          {item.invoice}
                        </p>
                      </div>
                      <div className="col-span-2 self-center">
                        <p className="text-center font-inter font-[500] text-[14px] text-[#2E3646]">
                          {item.amount}
                        </p>
                      </div>
                      <div className="col-span-3 self-center">
                        <p className="text-center font-inter font-[500] text-[14px] text-[#2E3646]">
                          {item.date}
                        </p>
                      </div>
                      <div className="col-span-2 ">
                        <button className="font-inter font-[400] text-[13px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-5 block mx-auto">
                          {item.btn}
                        </button>
                      </div>
                      <div className="col-span-2 self-center">
                        <p className="cursor-pointer text-center font-inter font-[500] text-[14px] text-[#5E83F5]">
                          {item.download}
                        </p>
                      </div>
                    </div>
                  ))}

                  {payment.map((item) => (
                    <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                      <div className=" flex gap-2 justify-center">
                        <input type="checkbox" className="mt-1" />
                        <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.invoice}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.amount}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.date}
                        </p>
                      </div>
                      <div className="mt-3">
                        <button className="font-inter font-[400] text-[16px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-5 block mx-auto">
                          {item.btn}
                        </button>
                      </div>
                      <div className="mt-3">
                        <p className="cursor-pointer text-center font-inter font-[500] text-[16px] text-[#5E83F5]">
                          {item.download}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-10 ">
                <div className="flex justify-between">
                  <p className="font-inter font-[700] text-[15px] md:text-[18px] text-black">
                  dossier de service
                  </p>

                  <p className="self-center cursor-pointer font-inter font-[500] text-[15px] md:text-[18px] text-[#5E83F5]">
                  Voir tout
                  </p>
                </div>

                <div className="pb-10">
                  <div className=" bg-[#BFD5FF] hidden md:grid  grid-cols-12  py-3 rounded-t-lg mt-3">
                    <div className="col-span-3 text-center">
                      <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        Services
                        {/* paiement est */}
                      </p>
                    </div>
                    <div className="col-span-2  text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        prix
                        {/* quantité */}
                      </p>
                    </div>
                    <div className="col-span-3 text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        datte
                      </p>
                    </div>
                    <div className="col-span-2 text-center ">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      Facture
                    
                      </p>
                    </div>
                    <div className="col-span-2 text-center ">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        télécharger
                      </p>
                    </div>
                  </div>

                  {service.map((item) => (
                    <div className="hidden md:grid grid-cols-12  py-[21px] border-[1px] border-gray-300 bg-white">
                      <div className="col-span-3  flex gap-2 justify-center">
                        <input type="checkbox" />
                        <p className=" self-center font-inter font-[700] text-[12px] text-[#2E3646]">
                          {item.services}
                        </p>
                      </div>
                      <div className="col-span-2 self-center">
                        <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                          {item.amount}
                        </p>
                      </div>
                      <div className="col-span-3 self-center">
                        <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                          {item.date}
                        </p>
                      </div>
                      <div className="col-span-2 ">
                        <p className=" self-center text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                          {item.invoice}
                        </p>
                      </div>
                      <div className="col-span-2 self-center">
                        <p className="cursor-pointer text-center font-inter font-[500] text-[12px] text-[#5E83F5]">
                          {item.download}
                        </p>
                      </div>
                    </div>
                  ))}

                  {service.map((item) => (
                    <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                      <div className=" flex gap-2 justify-center">
                        <input type="checkbox" className="mt-1" />
                        <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.services}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.amount}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.date}
                        </p>
                      </div>
                      <div className="mt-3">
                        <button className="font-inter font-[400] text-[16px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-5 block mx-auto">
                          {item.invoice}
                        </button>
                      </div>
                      <div className="mt-3">
                        <p className="cursor-pointer text-center font-inter font-[500] text-[16px] text-[#5E83F5]">
                          {item.download}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
    </>
  );
}
