import React from "react";

const ctable = [
  {
    name: "example",
    health: "Health",
    number: "1",
    case: "27",
    affected: "2",
    pro: "23",
    total: "23",
  },
  {
    name: "example",
    health: "Health",
    number: "1",
    case: "27",
    affected: "2",
    pro: "23",
    total: "23",
  },
  {
    name: "example",
    health: "Health",
    number: "1",
    case: "27",
    affected: "2",
    pro: "23",
    total: "23",
  },
  {
    name: "example",
    health: "Health",
    number: "1",
    case: "27",
    affected: "2",
    pro: "23",
    total: "23",
  },
  {
    name: "example",
    health: "Health",
    number: "1",
    case: "27",
    affected: "2",
    pro: "23",
    total: "23",
  },
  {
    name: "example",
    health: "Health",
    number: "1",
    case: "27",
    affected: "2",
    pro: "23",
    total: "23",
  },
];

export default function Cases() {
  return (
    <>
      <div className="flex justify-between">
        <p className="font-inter font-[700] text-[25px] text-black">Cases</p>
        <div className="flex gap-5">
          <button className="font-inter font-[500] text-[13px] md:text-[16px] text-white bg-[#8D6AFF] py-2 px-3 md:px-5 rounded-full">
            Processed
          </button>
          <p className="self-center cursor-pointer font-inter font-[500] text-[15px] md:text-[18px] text-[#5E83F5]">
            Voir tout
          </p>
        </div>
      </div>

      <div className="bg-[#BFD5FF] grid grid-cols-7 px-5 py-3 rounded-t-lg mt-10">
        <div className="">
          <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
            Name
          </p>
        </div>
        <div className="">
          <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
            Service Request
          </p>
        </div>
        <div className=" ">
          <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
            Request Number
          </p>
        </div>
        <div className="">
          <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
            Pending Case
          </p>
        </div>
        <div className="">
          <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
            Affected Case
          </p>
        </div>
        <div className="">
          <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
            Processed Case
          </p>
        </div>
        <div className=" ">
          <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
            Total Case Created
          </p>
        </div>
      </div>
      {ctable.map((item) => (
        <div className=" grid grid-cols-7 px-5 py-3 border-[1px] border-gray-300">
          <div className="self-center">
            <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
              {item.name}
            </p>
          </div>

          <div className="self-center">
            <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
              {item.health}
            </p>
          </div>

          <div className="self-center ">
            <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
              {item.number}
            </p>
          </div>

          <div className="self-center">
            <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
              {item.case}
            </p>
          </div>

          <div className="self-center">
            <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
              {item.affected}
            </p>
          </div>

          <div className="self-center">
            <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
              {item.pro}
            </p>
          </div>

          <div className="self-center">
            <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
              {item.total}
            </p>
          </div>

        </div>
      ))}
    </>
  );
}
