import React from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";
import User from "../assets/png/user.png";
import File from "../assets/png/file.png";
import Icon2 from "../assets/png/icon2.png";
import Icon from "../assets/png/icon.png";
import LineGraph from "../component/line-grph";
import Black from "../assets/jpg/181218171717-ymm-south-africa.jpg";
import Cases from "../component/cases";

const table = [
  {
    title: "Mener une interview   ",
    dp: Black,
    btn: "en instance",
  },
  {
    title: "Mener une interview   ",

    dp: Black,
    btn: "en instance",
  },
  {
    title: "Mener une interview   ",

    dp: Black,
    btn: "en instance",
  },
  {
    title: "Mener une interview   ",

    dp: Black,
    btn: "en instance",
  },
  {
    title: "Mener une interview   ",

    dp: Black,
    btn: "en instance",
  },
  {
    title: "Mener une interview   ",

    dp: Black,
    btn: "en instance",
  },
  {
    title: "Mener une interview   ",

    dp: Black,
    btn: "en instance",
  },
  {
    title: "Mener une interview   ",

    dp: Black,
    btn: "en instance",
  },
];


export default function Landing() {
  return (
    <>
      <div className="grid lg:grid-cols-12">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 h-[100vh] overflow-y-scroll">
          <Navbar />
          <div className="bg-[#f0f4f8]">
            <div className="w-[95%] mx-auto py-10">
              <p className="font-inter font-[700] text-[25px] text-black">
                {/* Dashboard */}
                Tableau de bord
              </p>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div>
                  <div className="grid  md:grid-cols-2 gap-5 md:gap-10 mt-5">
                    <div>
                      <div className="bg-[#FED70E] flex justify-around rounded-lg p-5 shadow-lg">
                        <div className="w-[60px] h-[60px]">
                          <img src={User} alt="" />
                        </div>
                        <div className="self-center">
                          <p className="text-center font-inter font-[700] text-[22px] text-white">
                            300
                          </p>
                          <p className="text-center font-inter font-[500] text-[14px] text-white">
                            {/* Total Users */}
                            Nombre total
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="bg-[#FE6B03] flex justify-around rounded-lg p-5 shadow-lg">
                        <div className="w-[60px] h-[60px]">
                          <img src={File} alt="" />
                        </div>
                        <div className="self-center">
                          <p className="text-center font-inter font-[700] text-[22px] text-white">
                            300
                          </p>
                          <p className="text-center font-inter font-[500] text-[14px] text-white">
                            {/* Total Files */}
                            Total fichiers
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-16">
                    <div className="bg-[#BFD5FF] grid grid-cols-12 px-5 py-3 rounded-t-lg ">
                      <div className="col-span-6">
                        <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                          {/* Task */}
                          Tâche
                        </p>
                      </div>
                      <div className="col-span-2 text-center">
                        <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                          {/* Assigned */}
                          Assigné
                        </p>
                      </div>
                      <div className="col-span-4 ml-auto mr-7">
                        <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                          {/* Status */}
                          Statut
                        </p>
                      </div>
                    </div>

                    {table.map((item) => (
                      <div className=" grid grid-cols-12 px-5 py-3 border-[1px] border-gray-300">
                        <div className="col-span-6 self-center">
                          <p className="font-inter font-[500] text-[10px] md:text-[13px] text-[#2E3646]">
                            {item.title}
                          </p>
                        </div>
                        <div className="col-span-2 self-center">
                          <img
                            src={item.dp}
                            alt=""
                            className="block mx-auto h-[30px] w-[30px] rounded-full"
                          />
                        </div>

                        <div className="col-span-4">
                          <button className="font-inter font-[400] text-[10px] md:text-[13px] text-[#FF6F00] border-[1px] border-[#FF6F00] rounded-full bg-[#fbf0f1] py-1 px-5 ml-auto block">
                            {item.btn}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="bg-white p-6 rounded-lg mt-5">
                  <div className="flex justify-between">
                    <p className="font-inter font-[600] text-[20px] text-black">
                      {/* Services */}
                      Prestations
                    </p>
                    <button className="flex gap-2 font-inter font-[500] text-[13px] md:text-[16px] text-white bg-[#8D6AFF] py-2 px-3 md:px-5 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 md:w-5 h-3 md:h-5 mt-[3px] md:mt-[2px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                      {/* Add Product */}
                      Ajouter un produit
                    </button>
                  </div>

                  <div className="md:flex justify-between mt-10  shadow-gray-400 shadow-sm p-5 rounded-md">
                    <div className="flex gap-3 ">
                      <img src={Icon2} alt="" className="w-[50px] h-[50px]" />
                      <p className="self-center font-inter font-[600] text-[16px] text-black">
                        {/* Health */}
                        Santé
                      </p>
                    </div>
                    <LineGraph />
                  </div>

                  <div className="flex justify-between mt-10  shadow-gray-400 shadow-sm p-5 rounded-md">
                    <div className="flex gap-3 ">
                      <img src={Icon} alt="" className="w-[50px] h-[50px]" />
                      <p className="self-center font-inter font-[600] text-[16px] text-black">
                        {/* Disability */}
                        Infirmité
                      </p>
                    </div>
                    <LineGraph />
                  </div>

                  <div className="flex justify-between mt-10  shadow-gray-400 shadow-sm p-5 rounded-md">
                    <div className="flex gap-3 ">
                      <img src={Icon2} alt="" className="w-[50px] h-[50px]" />
                      <p className="self-center font-inter font-[600] text-[16px] text-black">
                        {/* Retirement */}
                        Retraite
                      </p>
                    </div>
                    <LineGraph />
                  </div>

                  <div className="flex justify-between mt-10  shadow-gray-400 shadow-sm p-5 rounded-md">
                    <div className="flex gap-3 ">
                      <img src={Icon2} alt="" className="w-[50px] h-[50px]" />
                      <p className="self-center font-inter font-[600] text-[16px] text-black">
                        Santé
                      </p>
                    </div>
                    <LineGraph />
                  </div>
                </div>
              </div>

              <div className="mt-10 ">
                <Cases/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}