import React, { useState } from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";
import Front from "../assets/png/front.png";
import ReactCrop from "react-image-crop";
import Icon from "../assets/svg/delete.svg";
import Icon2 from "../assets/svg/retake.svg";
import Icon3 from "../assets/svg/crop.svg";
import "react-image-crop/dist/ReactCrop.css";

export default function Profile() {
    const [cropFront, setCropFront] = useState({ aspect: 16 / 9 });
    const [cropBack, setCropBack] = useState({ aspect: 16 / 9 });
  
    const onCropChange = (newCrop, id) => {
      if (id === "front") {
        setCropFront(newCrop);
      } else if (id === "back") {
        setCropBack(newCrop);
      }
    };
  return (
    <>
      <div className="grid lg:grid-cols-12 ">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 h-[100vh] overflow-y-scroll">
          <Navbar />
          <div className="w-[95%] mx-auto pt-10 mt-20 ">
            <p className="font-inter font-[700] text-[25px] text-black">
              Profile
            </p>

            <div className="grid md:grid-cols-4 gap-5">
              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  {/* First Name */}
                  prénom
                </p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">nom</p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">Gender</p>
                <select className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full">
                  <option>Male</option>
                  <option>Female</option>
                  <option>Other</option>
                </select>
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  {/* Date of Birth */}
                  date de naissance
                </p>
                <input
                  type="date"
                  className="mt-1 py-[2px] border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  {/* Email */}
                  Place of Birth
                </p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  Phone Number
                </p>
                <input
                  type="number"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  {/* Email */}
                  Courriel
                </p>
                <input
                  type="email"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  Martial Status
                </p>
                <select className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full">
                  <option>Single</option>
                  <option>Single</option>
                </select>
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  {/* Address */}
                  adresse
                </p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  Street Number
                </p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  Street Municipality
                </p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">District</p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">City</p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  Profession
                </p>
                <select className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full">
                  <option>Employee</option>
                  <option>Employee</option>
                </select>
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">Activity</p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  Number of Children
                </p>
                <input
                  type="number"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>
            </div>

            <p className="font-inter font-[700] text-[16px] pt-5 text-black">
              Children*
            </p>
            <div className="grid md:grid-cols-4 gap-5 mt-2">
              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  {/* First Name */}
                  prénom
                </p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">nom</p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  {/* Date of Birth */}
                  date de naissance
                </p>
                <input
                  type="date"
                  className="mt-1 py-[2px] border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>
            </div>
            <button className="font-[600] text-[16px] text-white bg-[#0049FC] py-1 px-5 rounded-md ml-auto block -mt-8">
              Edit
            </button>

            <div className="mt-10">
              <p className="font-inter font-[700] text-[16px]  text-black">
                Scanned Document
              </p>

              <div className="bg-white p-5 rounded-md shadow-md my-5">
                <div className="hidden md:flex justify-end gap-5 ">
                  <button className="flex gap-1 hover:bg-[#6D67EB] hover:text-white font-[400] text-[15px] text-[#6D67EB] border-[#6D67EB] border-[1px] rounded-full py-1 px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 mt-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                      />
                    </svg>
                    Edit
                  </button>

                  <button className="flex gap-1 hover:bg-[#6D67EB] hover:text-white font-[400] text-[15px] text-[#6D67EB] border-[#6D67EB] border-[1px] rounded-full py-1 px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 mt-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                      />
                    </svg>
                    Notify
                  </button>

                  <button className="flex gap-1 hover:bg-black hover:text-white font-[400] text-[15px] text-black border-black border-[1px] rounded-full py-1 px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 mt-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m6-6H6"
                      />
                    </svg>
                    Add Comment
                  </button>

                  <button className="flex gap-1 hover:bg-[#6D67EB] hover:text-white font-[400] text-[15px] text-[#6D67EB] border-[#6D67EB] border-[1px] rounded-full py-1 px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 mt-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                      />
                    </svg>
                    Cash in
                  </button>
                </div>

                <div className="grid lg:grid-cols-2 gap-10 mt-5">
                  <div>
                    <p className="font-[600] text-[14px] text-black">
                      Front Side
                    </p>

                    <div className="md:flex gap-5">
                      <div className="mt-5">
                        <ReactCrop
                          crop={cropFront}
                          onChange={(newCrop) => onCropChange(newCrop, "front")}
                        >
                          <img src={Front} />
                        </ReactCrop>
                      </div>

                      <div className="flex justify-between md:block self-center">
                        <div className="cursor-pointer">
                          <img src={Icon} alt="" className="block mx-auto" />
                          <p className="font-[400] text-[12px] text-[#5E83F5] text-center">
                            Delete
                          </p>
                        </div>

                        <div className="cursor-pointer">
                          <img
                            src={Icon2}
                            alt=""
                            className="md:mt-10 block m-auto"
                          />
                          <p className="font-[400] text-[12px] text-[#5E83F5] text-center">
                            Retake
                          </p>
                        </div>

                        <div className="cursor-pointer">
                          <img
                            src={Icon3}
                            alt=""
                            className="md:mt-10 block m-auto"
                          />
                          <p className="font-[400] text-[12px] text-[#5E83F5]">
                            Selection
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div>
                    <p className="font-[600] text-[14px] text-black">
                      Back Side
                    </p>

                    <div className="md:flex gap-5">
                      <div className="mt-5">
                        <ReactCrop
                          crop={cropBack}
                          onChange={(newCrop) => onCropChange(newCrop, "back")}
                        >
                          <img src={Front} />
                        </ReactCrop>
                      </div>

                      <div className="flex justify-between md:block self-center">
                        <div className="cursor-pointer">
                          <img src={Icon} alt="" className="block mx-auto" />
                          <p className="font-[400] text-[12px] text-[#5E83F5] text-center">
                            Delete
                          </p>
                        </div>

                        <div className="cursor-pointer">
                          <img
                            src={Icon2}
                            alt=""
                            className="md:mt-10 block m-auto"
                          />
                          <p className="font-[400] text-[12px] text-[#5E83F5] text-center">
                            Retake
                          </p>
                        </div>

                        <div className="cursor-pointer">
                          <img
                            src={Icon3}
                            alt=""
                            className="md:mt-10 block m-auto"
                          />
                          <p className="font-[400] text-[12px] text-[#5E83F5]">
                            Selection
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
