import React from "react";
import Call from "../assets/svg/material-symbols_call.svg";
import Video from "../assets/svg/mdi_video.svg";
import Option from "../assets/svg/bi_three-dots-vertical.svg";
import Bot from "../assets/svg/Group 232.svg";
import User from "../assets/svg/Ellipse 4.svg";
import Ic from "../assets/svg/ic.svg";
import Ic2 from "../assets/svg/ic2.svg";
import Ic3 from "../assets/svg/ic3.svg";
import Ic4 from "../assets/svg/ic4.svg";
import Ic5 from "../assets/svg/ic5.svg";
import Ic6 from "../assets/svg/ic6.svg";
import Ic7 from "../assets/svg/ic7.svg";
import Ic8 from "../assets/svg/ic8.svg";

export default function Chatscreen() {
  return (
    <>
      <div className=" overflow-y-scroll  h-[100vh]">
        <div className="flex justify-between bg-[#5E83F5] p-5 ">
          <div className="self-center">
            <p className="font-inter font-[600] text-[16px] md:text-[24px] text-white">
              Budiakala Fogo
            </p>
          </div>

          <div className="flex gap-5">
            <img
              src={Call}
              alt=""
              className="w-[25px] md:w-[42px] h-[25px] md:h-[42px] cursor-pointer"
            />
            <img
              src={Video}
              alt=""
              className="w-[25px] md:w-[42px] h-[25px] md:h-[42px] cursor-pointer"
            />
            <img
              src={Option}
              alt=""
              className="w-[25px] md:w-[42px] h-[25px] md:h-[42px] cursor-pointer"
            />
          </div>
        </div>

        <div className="bg-white pt-10 px-5 ">
          <div className="flex gap-3 md:w-[60%] ml-auto  ">
            <div className="">
              <p className="bg-[#5E83F5CC] p-5 rounded-3xl rounded-br-none font-[500] font-inter text-white text-[12px] ">
                Animations can enhance user engagement, but use them
                judiciously. Subtle animations for transitions or highlighting
                elements can make the site feel dynamic without overwhelming
                users.
              </p>

              <p className="font-[400] font-inter text-black text-[12px] mt-2">
                3:17 PM{" "}
              </p>
            </div>
            <img src={Bot} alt="" className="self-end" />
          </div>

          <div className="flex gap-3 md:w-[50%] mt-10">
            <img src={User} alt="" className="self-end" />

            <div className="">
              <p className="bg-[#f7f8f8] border-[1px] border-[#e0e0e0] p-5 rounded-3xl rounded-bl-none font-[600] font-inter text-black text-[12px] ">
                That makes sense. How about mobile responsiveness? It's a must
                nowadays, right?
              </p>

              <p className="text-right font-[400] font-inter text-black text-[12px] mt-2">
                3:17 PM{" "}
              </p>
            </div>
          </div>

          <div className="flex gap-3 md:w-[60%] ml-auto  mt-10">
            <div className="">
              <p className="bg-[#5E83F5CC] p-5 rounded-3xl rounded-br-none font-[500] font-inter text-white text-[12px] ">
                Animations can enhance user engagement, but use them
                judiciously. Subtle animations for transitions or highlighting
                elements can make the site feel dynamic without overwhelming
                users.
              </p>

              <p className="font-[400] font-inter text-black text-[12px] mt-2">
                3:17 PM{" "}
              </p>
            </div>
            <img src={Bot} alt="" className="self-end" />
          </div>

          <div className="flex gap-3  md:w-[50%] mt-10">
            <img src={User} alt="" className="self-end" />

            <div className="">
              <p className="bg-[#f7f8f8] border-[1px] border-[#e0e0e0] p-5 rounded-3xl rounded-bl-none font-[600] font-inter text-black text-[12px] ">
                That makes sense. How about mobile responsiveness? It's a must
                nowadays, right?
              </p>

              <p className="text-right font-[400] font-inter text-black text-[12px] mt-2">
                3:17 PM{" "}
              </p>
            </div>
          </div>

          <div className="flex gap-3 md:w-[60%] ml-auto  mt-10">
            <div className="">
              <p className="bg-[#5E83F5CC] p-5 rounded-3xl rounded-br-none font-[500] font-inter text-white text-[12px] ">
                Animations can enhance user engagement, but use them
                judiciously. Subtle animations for transitions or highlighting
                elements can make the site feel dynamic without overwhelming
                users.
              </p>

              <p className="font-[400] font-inter text-black text-[12px] mt-2">
                3:17 PM{" "}
              </p>
            </div>
            <img src={Bot} alt="" className="self-end" />
          </div>

          <div className="flex gap-3 md:w-[50%] mt-10">
            <img src={User} alt="" className="self-end" />

            <div className="">
              <p className="bg-[#f7f8f8] border-[1px] border-[#e0e0e0] p-5 rounded-3xl rounded-bl-none font-[600] font-inter text-black text-[12px] ">
                That makes sense. How about mobile responsiveness? It's a must
                nowadays, right?
              </p>

              <p className="text-right font-[400] font-inter text-black text-[12px] mt-2">
                3:17 PM{" "}
              </p>
            </div>
          </div>

          <div className="pb-5 pt-16">
            <input
              type="text"
              placeholder="Choose readable fonts that align with the brand's vibe. Combining a clean sans-serif with|"
              className="font-inter font-[500] text-[14px] text-[#171C1B] w-full  px-3 pt-2 pb-16 outline-none  border-[1px] border-[#b4afaf] rounded-2xl rounded-b-none"
            />
            <div className="grid grid-cols-2 p-3 border-[1px] border-t-0 border-[#b4afaf] rounded-b-2xl">
              <div className="flex gap-5">
                <img src={Ic} alt="" className="cursor-pointer" />
                <img src={Ic2} alt="" className="cursor-pointer" />
                <img src={Ic3} alt="" className="cursor-pointer" />
                <img src={Ic4} alt="" className="cursor-pointer" />
                <img src={Ic5} alt="" className="cursor-pointer" />
                <img src={Ic6} alt="" className="cursor-pointer " />
                <img src={Ic7} alt="" className="cursor-pointer hidden md:block" />
              </div>
              <div className="ml-auto">
                <img src={Ic8} alt="" className="cursor-pointer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
