import React from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";
import Ser from "../assets/png/ser.png";
import Ser2 from "../assets/png/ser2.png";
import Ser3 from "../assets/png/ser3.png";
import Ser4 from "../assets/png/ser4.png";

const service = [
  {
    img: Ser,
    title: "Health",
    btn: "300$",
  },
  {
    img: Ser2,
    title: "Disability",
    btn: "300$",
  },
  {
    img: Ser3,
    title: "Retirement",
    btn: "300$",
  },
  {
    img: Ser4,
    title: "Death",
    btn: "300$",
  },
];

const text = [
  {
    services: "3  ",
    amount: "Married",
    date: "100$",
    invoice: "10%",
  },
  {
    services: "3  ",
    amount: "Married",
    date: "100$",
    invoice: "10%",
  },
  {
    services: "3  ",
    amount: "Married",
    date: "100$",
    invoice: "10%",
  },
  {
    services: "3  ",
    amount: "Married",
    date: "100$",
    invoice: "10%",
  },
  {
    services: "3  ",
    amount: "Married",
    date: "100$",
    invoice: "10%",
  },
];

export default function Setting() {
  return (
    <>
      <div className="grid lg:grid-cols-12 ">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 h-[100vh] overflow-y-scroll">
          <Navbar />
          <div className="w-[95%] mx-auto pt-10 mt-20 ">
            <p className="font-inter font-[700] text-[25px] text-black">
              Settings
            </p>

            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10">
              {service.map((item) => (
                <div className="bg-white p-10 mt-10 shadow-md rounded-2xl">
                  <div className="flex gap-5">
                    <img
                      src={item.img}
                      alt=""
                      className="w-[66px] h-[66px] rounded-full"
                    />
                    <p className="font-[500] text-[20px] text-black self-center">
                      {item.title}
                    </p>
                  </div>
                  <button className="mt-10 font-inter font-[500] text-[16px] bg-[#5E83F5] text-white py-1 w-[80%] block mx-auto rounded-full">
                    {item.btn}
                  </button>
                </div>
              ))}
            </div>

            <p className="font-inter font-[600] text-[20px] text-black mt-10">
              Eligibility Criteria
            </p>

            <div className="grid md:grid-cols-4 gap-5 mt-5">
              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  Number of Children
                </p>
                <input
                  type="number"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>
              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                  Martial Status
                </p>
                <input
                  type="text"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">Income</p>
                <input
                  type="number"
                  className="mt-1 py-1 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>
            </div>

            <p className="font-inter font-[600] text-[20px] text-black mt-10">
              Tax Configuration
            </p>

            <div className="pb-10">
              <div className=" bg-[#BFD5FF] hidden md:grid  grid-cols-12  py-3 rounded-t-lg mt-3">
                <div className="col-span-3 text-center">
                  <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    Number of children
                    {/* paiement est */}
                  </p>
                </div>
                <div className="col-span-2  text-center">
                  <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    Martial Status
                    {/* quantité */}
                  </p>
                </div>
                <div className="col-span-3 text-center">
                  <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    Income
                  </p>
                </div>
                <div className="col-span-2 text-center ">
                  <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    Tax
                  </p>
                </div>
              </div>

              {text.map((item) => (
                <div className="hidden md:grid grid-cols-12  py-[21px] border-[1px] border-gray-300 bg-white">
                  <div className="col-span-3  flex justify-center">
                    <p className=" self-center font-inter font-[500] text-[12px] text-[#2E3646]">
                      {item.services}
                    </p>
                  </div>
                  <div className="col-span-2 self-center">
                    <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                      {item.amount}
                    </p>
                  </div>
                  <div className="col-span-3 self-center">
                    <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                      {item.date}
                    </p>
                  </div>
                  <div className="col-span-2 ">
                    <p className=" self-center text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                      {item.invoice}
                    </p>
                  </div>
                </div>
              ))}

              {text.map((item) => (
                <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                  <div className=" flex justify-center">
                    <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                      {item.services}
                    </p>
                  </div>
                  <div className="mt-3">
                    <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                      {item.amount}
                    </p>
                  </div>
                  <div className="mt-3">
                    <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                      {item.date}
                    </p>
                  </div>
                  <div className="mt-3">
                    <button className="font-inter font-[400] text-[16px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-5 block mx-auto">
                      {item.invoice}
                    </button>
                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
