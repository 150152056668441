import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./inter/stylesheet.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Inbox from "./pages/inbox";
import Chatscreen from "./pages/chatscreen";
import Contact from "./pages/contact";
import Login from "./pages/login";
import Landing from "./pages/home";
import Ongoing from "./pages/ongoing";
import Affected from "./pages/affected";
import Profile from "./pages/profile";
import History from "./pages/history";
import Setting from "./pages/setting";

function App() {
  return (
    <>
      <Router>
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Landing />} />
        <Route path="/ongoing" element={<Ongoing />} />
        <Route path="/affected" element={<Affected />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/chat" element={<Chatscreen />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/history" element={<History />} />
        <Route path="/setting" element={<Setting />} />


            </Routes>
      </Router>
    </>
  );
}

export default App;
