import React from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";

const request = [
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },
  {
    date: "1234",
    example: "1234",
    afternoon: "11/23/2023",
    appro: "Ongoing",
  },

  
];

export default function Affected() {
  return (
    <>
      <div className="grid lg:grid-cols-12 ">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 h-[100vh] overflow-y-scroll">
          <Navbar />
          <div className="w-[95%] mx-auto pt-10 mt-20 ">
            <p className="font-inter font-[700] text-[25px] text-black">
            Affected Cases
            </p>

            <div className="mt-10">
              <div className="flex justify-between">
                <p className="font-inter font-[700] text-[15px] md:text-[18px] text-black">
                Cases
                </p>

                <p className="self-center cursor-pointer font-inter font-[500] text-[15px] md:text-[18px] text-[#5E83F5]">
                Voir tout
                </p>
              </div>

              <div>
                <div className=" bg-[#BFD5FF] hidden md:grid  grid-cols-12  py-3 rounded-t-lg mt-3">
                  <div className="col-span-3 text-center">
                    <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    Customer Number
                    </p>
                  </div>
                  <div className="col-span-3  text-center">
                    <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    Ongoing
                      {/* quantité */}
                    </p>
                  </div>
                  <div className="col-span-3 text-center">
                    <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      datte
                    </p>
                  </div>
                  <div className="col-span-3 text-center ">
                    <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      
                      statut
                    </p>
                  </div>
                </div>

                {request.map((item) => (
                  <div className="hidden md:grid grid-cols-12  py-4 border-[1px] border-gray-300 bg-white">
                    <div className="col-span-3 self-center">
                      <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                        {item.date}
                      </p>
                    </div>

                    <div className="col-span-3 self-center">
                      <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                        {item.example}
                      </p>
                    </div>

                    <div className="col-span-3 self-center">
                      <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                        {item.afternoon}
                      </p>
                    </div>
                    <div className="col-span-3  self-center">
                      <button className="font-inter font-[400] text-[12px] text-[#67BF67] border-[1px] border-[#67BF6766] rounded-full bg-[#e6f2e9] py-1 px-4 block mx-auto">
                        {item.appro}
                      </button>
                    </div>
                  </div>
                ))}
                {request.map((item) => (
                  <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                    <div className=" flex gap-2 justify-center">
                      <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                        {item.date}
                      </p>
                    </div>
                    <div className="mt-3">
                      <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                        {item.example}
                      </p>
                    </div>
                    <div className="mt-3">
                      <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                        {item.afternoon}
                      </p>
                    </div>
                    <div className="mt-3">
                      <button className="font-inter font-[400] text-[12px] text-[#67BF67] border-[1px] border-[#67BF6766] rounded-full bg-[#e6f2e9] py-1 px-4 block mx-auto">
                        {item.appro}
                      </button>
                    </div>
                    <div className="mt-3">
                      <p className="cursor-pointer text-center font-inter font-[500] text-[16px] text-[#5E83F5]">
                        {item.cla}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>


          </div>
          <div className="flex w-[95%] mx-auto justify-between my-10">
        <div className="flex gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 mt-1 text-[#5F6D7E]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>

          <p className="font-inter font-[600] text-[14px] text-[#5F6D7E]">
            {/* Prev */}
            Préc
          </p>
        </div>

        <div className="flex gap-2">
          <p className="font-inter font-[600] text-[14px] text-[#5F6D7E]">1</p>
          <p className="font-inter font-[600] text-[14px] text-[#5F6D7E]">2</p>
          <p className="font-inter font-[600] text-[14px] text-[#5F6D7E]">
            ...
          </p>
          <p className="font-inter font-[600] text-[14px] text-[#5F6D7E]">5</p>
          <p className="font-inter font-[600] text-[14px] text-[#5F6D7E]">6</p>
        </div>

        <div className="flex gap-1">
          <p className="font-inter font-[600] text-[14px] text-[#5F6D7E]">
            {/* Next */}
            Prochain
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 text-[#5F6D7E] mt-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </div>
      </div>

        </div>
      </div>


    </>
  );
}
