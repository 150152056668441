import React from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";
import Elipse from "../assets/svg/Ellipse 3.svg";
import Bell from "../assets/svg/ph_bell.svg";
import Dp from "../assets/svg/Ellipse 6.svg";
import Dp2 from "../assets/svg/Ellipse 7.svg";
import Dp3 from "../assets/svg/Ellipse 8.svg";
import Call from "../assets/svg/material-symbols_call.svg";
import Video from "../assets/svg/mdi_video.svg";
import Option from "../assets/svg/bi_three-dots-vertical.svg";
import Bot from "../assets/svg/Group 232.svg";
import User from "../assets/svg/Ellipse 4.svg";
import { Link } from "react-router-dom";
import Ic from "../assets/svg/ic.svg";
import Ic2 from "../assets/svg/ic2.svg";
import Ic3 from "../assets/svg/ic3.svg";
import Ic4 from "../assets/svg/ic4.svg";
import Ic5 from "../assets/svg/ic5.svg";
import Ic6 from "../assets/svg/ic6.svg";
import Ic7 from "../assets/svg/ic7.svg";
import Ic8 from "../assets/svg/ic8.svg";

const chat = [
  {
    img: Dp,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
    time: "5s",
  },
  {
    img: Dp2,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
    time: "5s",
  },
  {
    img: Dp3,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
    time: "5s",
  },
  {
    img: Dp,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
    time: "5s",
  },
  {
    img: Dp2,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
    time: "5s",
  },
  {
    img: Dp3,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
  },
  {
    img: Dp,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
  },
  {
    img: Dp2,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
  },
  {
    img: Dp3,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
  },
  {
    img: Dp,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
  },
  {
    img: Dp2,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
  },
  {
    img: Dp3,
    title: "Budiakala Fogo",
    des: "Hey there! 🌼 Did you catch the latest episode of this series, it...",
  },
];
export default function Inbox() {
  return (
    <>
      <div className="grid lg:grid-cols-12 ">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 ">
          <Navbar />
          <div className="w-[95%] mx-auto pt-10 mt-20 ">
            <p className="font-inter font-[700] text-[25px] text-black">
              Messagerie
            </p>
            <div className="grid lg:grid-cols-12 mt-10 ">
              <div className=" lg:col-span-4 bg-white pt-8 ">
                <div className="flex justify-between px-6">
                  <div className="flex gap-3">
                    <img src={Elipse} alt="" className="cursor-pointer" />
                    <div>
                      <p className="font-inter font-[700] text-[15px] xl:text-[18px] text-black">
                        Saleha Jamshed
                      </p>
                      <p className="font-inter font-[200] text-[12px] xl:text-[14px] text-black">
                        @saleha_123
                      </p>
                    </div>
                  </div>

                  <img src={Bell} alt="" className="cursor-pointer" />
                </div>

                <div className="mt-10 mb-5 px-6">
                  <p className="font-inter font-[700] text-[18px] text-black">
                    Messages
                  </p>

                  <div className=" relative mt-5">
                    <input
                      type="text"
                      placeholder="Search"
                      className=" font-[500] font-inter text-[14px] text-[#BFBFBF] border-[1px] border-[#BFBFBF] py-2 px-8 w-full rounded-md"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 text-[#BFBFBF] absolute top-3 left-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </div>
                </div>

                <div className="hidden lg:block overflow-y-scroll h-[49vh] xl:h-[40vh]">
                  {chat.map((item) => (
                    <div className="hover:bg-[#f0f0fd] border-b-[1px] border-[#BFBFBF] ">
                      <div className="flex gap-4 p-6 cursor-pointer">
                        <img src={item.img} alt="" />
                        <div className="w-[70%]">
                          <p className="font-inter font-[700] text-[14px] xl:text-[16px] text-black">
                            {item.title}
                          </p>
                          <p className="font-inter font-[100] text-[10px] xl:text-[12px] text-black">
                            {item.des}
                          </p>
                        </div>

                        <p className="ml-auto font-inter font-[100] text-[12px] text-black">
                          {item.time}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="block lg:hidden overflow-y-scroll h-[49vh] xl:h-[40vh]">
                  {chat.map((item) => (
                    <Link to="/chat">
                      <div className="hover:bg-[#f0f0fd] border-b-[1px] border-[#BFBFBF] ">
                        <div className="flex gap-4 p-6 cursor-pointer">
                          <img src={item.img} alt="" />
                          <div className="w-[70%]">
                            <p className="font-inter font-[700] text-[14px] xl:text-[16px] text-black">
                              {item.title}
                            </p>
                            <p className="font-inter font-[100] text-[10px] xl:text-[12px] text-black">
                              {item.des}
                            </p>
                          </div>

                          <p className="ml-auto font-inter font-[100] text-[12px] text-black">
                            {item.time}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>

              <div className="hidden lg:block lg:col-span-8 overflow-y-scroll  h-[76vh] xl:h-[72vh]">
                <div className="flex justify-between bg-[#5E83F5] p-5 ">
                  <div className="self-center">
                    <p className="font-inter font-[600] text-[24px] text-white">
                      Budiakala Fogo
                    </p>
                  </div>

                  <div className="flex gap-10">
                    <img
                      src={Call}
                      alt=""
                      className="w-[42px] h-[42px] cursor-pointer"
                    />
                    <img
                      src={Video}
                      alt=""
                      className="w-[42px] h-[42px] cursor-pointer"
                    />
                    <img
                      src={Option}
                      alt=""
                      className="w-[42px] h-[42px] cursor-pointer"
                    />
                  </div>
                </div>

                <div className="bg-white pt-10 px-5 ">
                  <div className="flex gap-3 w-[60%] ml-auto  ">
                    <div className="">
                      <p className="bg-[#5E83F5CC] p-5 rounded-3xl rounded-br-none font-[500] font-inter text-white text-[12px] ">
                        Les animations peuvent améliorer l’engagement des
                        utilisateurs, mais utilisez-les judicieusement. Des
                        animations subtiles pour les transitions ou la mise en
                        évidence d'éléments peuvent rendre le site dynamique
                        sans surcharger les utilisateurs.
                        
                      </p>

                      <p className="font-[400] font-inter text-black text-[12px] mt-2">
                        3:17 PM{" "}
                      </p>
                    </div>
                    <img src={Bot} alt="" className="self-end" />
                  </div>

                  <div className="flex gap-3 w-[50%] mt-10">
                    <img src={User} alt="" className="self-end" />

                    <div className="">
                      <p className="bg-[#f7f8f8] border-[1px] border-[#e0e0e0] p-5 rounded-3xl rounded-bl-none font-[600] font-inter text-black text-[12px] ">
                        Ça a du sens. Qu’en est-il de la réactivité mobile ?
                        C'est un incontournable de nos jours, non ?

                      </p>

                      <p className="text-right font-[400] font-inter text-black text-[12px] mt-2">
                        3:17 PM{" "}
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-3 w-[60%] ml-auto  mt-10">
                    <div className="">
                      <p className="bg-[#5E83F5CC] p-5 rounded-3xl rounded-br-none font-[500] font-inter text-white text-[12px] ">
                      Les animations peuvent améliorer l’engagement des
                        utilisateurs, mais utilisez-les judicieusement. Des
                        animations subtiles pour les transitions ou la mise en
                        évidence d'éléments peuvent rendre le site dynamique
                        sans surcharger les utilisateurs.

                        
                      </p>

                      <p className="font-[400] font-inter text-black text-[12px] mt-2">
                        3:17 PM{" "}
                      </p>
                    </div>
                    <img src={Bot} alt="" className="self-end" />
                  </div>

                  <div className="flex gap-3 w-[50%] mt-10">
                    <img src={User} alt="" className="self-end" />

                    <div className="">
                      <p className="bg-[#f7f8f8] border-[1px] border-[#e0e0e0] p-5 rounded-3xl rounded-bl-none font-[600] font-inter text-black text-[12px] ">
                      Ça a du sens. Qu’en est-il de la réactivité mobile ?
                        C'est un incontournable de nos jours, non ?

                        
                      </p>

                      <p className="text-right font-[400] font-inter text-black text-[12px] mt-2">
                        3:17 PM{" "}
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-3 w-[60%] ml-auto  mt-10">
                    <div className="">
                      <p className="bg-[#5E83F5CC] p-5 rounded-3xl rounded-br-none font-[500] font-inter text-white text-[12px] ">
                      Les animations peuvent améliorer l’engagement des
                        utilisateurs, mais utilisez-les judicieusement. Des
                        animations subtiles pour les transitions ou la mise en
                        évidence d'éléments peuvent rendre le site dynamique
                        sans surcharger les utilisateurs.
                        
                        
                      </p>

                      <p className="font-[400] font-inter text-black text-[12px] mt-2">
                        3:17 PM{" "}
                      </p>
                    </div>
                    <img src={Bot} alt="" className="self-end" />
                  </div>

                  <div className="flex gap-3 w-[50%] mt-10">
                    <img src={User} alt="" className="self-end" />

                    <div className="">
                      <p className="bg-[#f7f8f8] border-[1px] border-[#e0e0e0] p-5 rounded-3xl rounded-bl-none font-[600] font-inter text-black text-[12px] ">
                      Ça a du sens. Qu’en est-il de la réactivité mobile ?
                        C'est un incontournable de nos jours, non ?
                        
                      </p>

                      <p className="text-right font-[400] font-inter text-black text-[12px] mt-2">
                        3:17 PM{" "}
                      </p>
                    </div>
                  </div>

                  <div className="pb-5 pt-16">
                    <input
                      type="text"
                      placeholder="Choose readable fonts that align with the brand's vibe. Combining a clean sans-serif with|"
                      className="font-inter font-[500] text-[14px] text-[#171C1B] w-full  px-3 pt-2 pb-16 outline-none  border-[1px] border-[#b4afaf] rounded-2xl rounded-b-none"
                    />
                    <div className="grid grid-cols-2 p-3 border-[1px] border-t-0 border-[#b4afaf] rounded-b-2xl">
                      <div className="flex gap-5">
                        <img src={Ic} alt="" className="cursor-pointer" />
                        <img src={Ic2} alt="" className="cursor-pointer" />
                        <img src={Ic3} alt="" className="cursor-pointer" />
                        <img src={Ic4} alt="" className="cursor-pointer" />
                        <img src={Ic5} alt="" className="cursor-pointer" />
                        <img src={Ic6} alt="" className="cursor-pointer" />
                        <img src={Ic7} alt="" className="cursor-pointer" />
                      </div>
                      <div className="ml-auto">
                        <img src={Ic8} alt="" className="cursor-pointer" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
