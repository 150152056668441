import React from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";

const product = [
  {
    invoice: "Urgence",
    amount: "112",

    btn: "appel",
  },
  {
    invoice: "Urgence",
    amount: "112",

    btn: "appel",
  },
  {
    invoice: "Urgence",
    amount: "112",

    btn: "appel",
  },
  {
    invoice: "Urgence",
    amount: "112",

    btn: "appel",
  },
];

export default function Contact() {
  return (
    <>
      <div className="grid lg:grid-cols-12 ">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 ">
          <Navbar />
          <div className="w-[95%] mx-auto pt-10 mt-20 ">
            <p className="font-inter font-[700] text-[25px] text-black">
              Contacts utiles
            </p>

            <div>
              <div className=" bg-[#BFD5FF] hidden md:grid  grid-cols-12  py-3 rounded-t-lg mt-3">
                <div className="col-span-4 text-center">
                  <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    Statut
                    {/* paiement est */}
                  </p>
                </div>
                <div className="col-span-4  text-center">
                  <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    quantité
                  </p>
                </div>
                <div className="col-span-4 text-center">
                  <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    appel
                  </p>
                </div>
              </div>

              {product.map((item) => (
                <div className="hidden md:grid grid-cols-12  py-[15px] border-[1px] border-gray-300 bg-white">
                  <div className="col-span-4 text-center">
                    <p className=" self-center font-inter font-[500] text-[14px] text-[#2E3646]">
                      {item.invoice}
                    </p>
                  </div>
                  <div className="col-span-4 self-center">
                    <p className="text-center font-inter font-[500] text-[14px] text-[#2E3646]">
                      {item.amount}
                    </p>
                  </div>

                  <div className="col-span-4 ">
                    <button className="flex gap-2 font-inter font-[400] text-[13px] text-white  rounded-full bg-[#5E83F5] py-[6px] px-5 mx-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {item.btn}
                    </button>
                  </div>
                </div>
              ))}

              {product.map((item) => (
                <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                  <div className=" flex gap-2 justify-center">
                    <input type="checkbox" className="" />
                    <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                      {item.invoice}
                    </p>
                  </div>
                  <div className="mt-3">
                    <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                      {item.amount}
                    </p>
                  </div>
                  <div className="mt-3">
                    <button className="font-inter font-[400] text-[13px] text-white  rounded-full bg-[#5E83F5] py-[6px] px-5 block mx-auto">
                      {item.btn}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
